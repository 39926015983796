import { Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
var ɵ0 = { preload: true }, ɵ1 = { name: '/dashboard' }, ɵ2 = { name: '/list' }, ɵ3 = { name: '/listeditable' }, ɵ4 = { name: '/clientsform' }, ɵ5 = { name: '/productsform' }, ɵ6 = { name: '/usersform' }, ɵ7 = { name: '/projectslist' }, ɵ8 = { name: '/teamprojectslist' }, ɵ9 = { name: '/projectsforsendingslist' }, ɵ10 = { name: '/registeredprojectslist' }, ɵ11 = { name: '/projectsform' }, ɵ12 = { name: '/teamprojectsform' }, ɵ13 = { name: '/projectsforsendingsform' }, ɵ14 = { name: '/registeredprojectsform' }, ɵ15 = { name: '/projectrequirementsform' }, ɵ16 = { name: '/projectrequirementoptionsform' }, ɵ17 = { name: '/projectdetailsform' }, ɵ18 = { name: '/solutionsform' }, ɵ19 = { name: '/solutionledsform' };
var routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginPage, data: ɵ0 },
    { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule' },
    { path: 'comingsoon', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },
    { path: 'changepassword', loadChildren: './pages/changepassword/changepassword.module#ChangePasswordPageModule' },
    { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', data: ɵ1 },
    { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
    { path: 'appsettings', loadChildren: './pages/appsettings/form.module#FormPageModule' },
    { path: 'list', loadChildren: './pages/list/list.module#ListPageModule', data: ɵ2 },
    { path: 'listeditable', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule', data: ɵ3 },
    /* PROFILES */
    { path: 'clientslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'clientsform', loadChildren: './pages/clientsform/form.module#FormPageModule', data: ɵ4 },
    { path: 'classificationslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    { path: 'productslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'productsform', loadChildren: './pages/productsform/form.module#FormPageModule', data: ɵ5 },
    { path: 'userslist', loadChildren: './pages/list/list.module#ListPageModule' },
    { path: 'usersform', loadChildren: './pages/usersform/usersform.module#UsersFormPageModule', data: ɵ6 },
    // { path: 'pricelist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
    { path: 'pricelist', loadChildren: './pages/pricelistslist/form.module#FormPageModule' },
    /* PROJECTS */
    { path: 'projectslist', loadChildren: './pages/list/list.module#ListPageModule', data: ɵ7 },
    { path: 'teamprojectslist', loadChildren: './pages/list/list.module#ListPageModule', data: ɵ8 },
    { path: 'projectsforsendingslist', loadChildren: './pages/list/list.module#ListPageModule', data: ɵ9 },
    { path: 'registeredprojectslist', loadChildren: './pages/list/list.module#ListPageModule', data: ɵ10 },
    { path: 'projectsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: ɵ11 },
    { path: 'teamprojectsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: ɵ12 },
    { path: 'projectsforsendingsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: ɵ13 },
    { path: 'registeredprojectsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: ɵ14 },
    { path: 'projectrequirementsform', loadChildren: './pages/projectrequirementsform/form.module#FormPageModule', data: ɵ15 },
    { path: 'projectrequirementoptionsform', loadChildren: './pages/projectrequirementoptionsform/form.module#FormPageModule', data: ɵ16 },
    { path: 'projectdetailsform', loadChildren: './pages/projectdetailsform/form.module#FormPageModule', data: ɵ17 },
    /* SOLUTIONS */
    { path: 'forsolutionslist', loadChildren: './pages/solutionslist/form.module#FormPageModule' },
    { path: 'solutionsform', loadChildren: './pages/solutionsform/form.module#FormPageModule', data: ɵ18 },
    { path: 'solutionsmanagementslist', loadChildren: './pages/solutionslist/form.module#FormPageModule' },
    { path: 'solutionledsform', loadChildren: './pages/solutionledsform/form.module#FormPageModule', data: ɵ19 },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
