import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Events } from './services/events.service';
import { Global4Service } from './services/global4.service';

import {
  faAngleRight,
  faAngleDown,
  faHome,
  faTachometerAlt,
  faBell,
  faLock,
  faSignOutAlt,
  faUserCircle,
  faUsers,
  faUserTie,
  faChartPie,
  faQuoteLeft,
  faThumbsUp,
  faTv,
  faNetworkWired,
  faClipboardList,
  faDollarSign,
  faCogs,
  faHandPointRight,
  faBriefcase,
  faEnvelope,
  faBrain,
  faPenNib,
  faPen,
  faFileInvoice
} from '@fortawesome/free-solid-svg-icons';

// import {
  
// } from '@fortawesome/free-regular-svg-icons';

import {
  faGalacticRepublic,
  faFirstOrderAlt
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  faPen = faPen;
  faPenNib = faPenNib;
  faBrain = faBrain;
  faEnvelope = faEnvelope;
  faBriefcase = faBriefcase;
  faHandPointRight = faHandPointRight;
  faCogs = faCogs;
  faDollarSign = faDollarSign;
  faClipboardList = faClipboardList;
  faNetworkWired = faNetworkWired;
  faAngleRight = faAngleRight;
  faAngleDown = faAngleDown;
  faHome = faHome;
  faTachometerAlt = faTachometerAlt;
  faBell = faBell;
  faLock = faLock;
  faSignOutAlt = faSignOutAlt;
  faUsers = faUsers;
  faUserCircle = faUserCircle;
  faUserTie = faUserTie;
  faChartPie = faChartPie;
  faGalacticRepublic = faGalacticRepublic;
  faFirstOrderAlt = faFirstOrderAlt;
  faQuoteLeft = faQuoteLeft;
  faThumbsUp = faThumbsUp;
  faTv = faTv;
  faFileInvoice = faFileInvoice;

  public Notification: number = 0;
  public Menu: any;

  constructor(
    public global: Global4Service,
    private platform: Platform,
    private events: Events
  ) {
    this.global.CheckVersion();

    this.platform.ready().then((readySource) => {
      this.global.platformWidth = this.platform.width();

      this.platform.resize.subscribe(() => {
        this.global.platformWidth = this.platform.width();
      });
    });

    this.Menu = new Array();
    this.global.GetSessionUser();
    this.CheckNotification();
  }

  Toggle(Menu: string) {
    this.Menu[Menu] = !this.Menu[Menu];
  }

  MenuRoute(Page: string, IsMyFunction: boolean = false) {
    let RowError: boolean = false;
    if (this.global.ParamEditablePage !== '') {
      RowError = this.global.ToBoolean(this.events.publish (this.global.ParamEditablePage.toLowerCase() + 'status'));
    }

    RowError = this.global.IsUndefined(RowError, false);

    if (RowError === false) {
      this.GotoPage (Page, IsMyFunction);
    } else {
      this.global.Alert (
        'Prompt',
        'You have unsaved data. Do you want to continue to move away from this page?',
        [
          {
            text: 'No',
            role: 'cancel'
          },
          {
            text: 'Yes',
            handler: () => {
              this.GotoPage (Page, IsMyFunction);
            }
          }
        ]
      );
    }
  }

  CheckNotification() {
    if ((this.global.GetUser('UsersKey') !== '***admin***') &&
        (this.global.GetUser('UsersKey') !== '')) {
      let _global: any;
      _global = this.global;

      _global.CheckNotification();
      setInterval(function() {
          _global.CheckNotification();
        }, 30000);
    }
  }

  GotoPage(Page: string, IsMyFunction: boolean = false) {
    this.global.ParamPage = Page;
    this.global.ParamSourcePage = '';
    this.global.ParamEditablePage = '';
    
    this.global.SetParam ('Page', Page);
    this.global.SetParam ('EditablePage', '');
    this.global.SetParam ('SourcePage', '');
    this.global.SetParam ('Dashboard', 'false');
    this.global.SetParam ('Notification', 'false');
    this.global.SetParam ('MyFunction', IsMyFunction.toString());
    // this.global.SetParam ('ParamID', '0');
    this.global.SetParam ('Option1', '0');

    this.global.Loading ('Loading...', 0, '', Page, 'ROOT');
  }
}
