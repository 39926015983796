import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Config {

  public ApplicationName: string = 'United Neon MDS';
  public SchemaName: string = 'UNMDS';
  public NotificationAppID: string = '0';

  public User: any = new Array();

  public PageSize: number = 100;
  public EditablePageSize: number = 100;

  public SourceURL: string;
  public URL: string;
  public NotificationURL: string;
  public UploadURL: string;
  public DeleteURL: string;
  public DefaultURL: string;
  public TemplateURL: string;
  public Directory: string;

  public Debug: boolean = true; // false

  public Version: string = '2024.08.26.1559';

  constructor(
  ) {
/*
    NOTE:
      DefaultURL
      TemplateURL
      Directory
    - use global.XMLEncode
*/
    // this.SourceURL = environment.SourceURL;
    // this.URL = environment.URL;
    // this.NotificationURL = environment.NotificationURL;
    // this.UploadURL = environment.UploadURL;
    // this.DeleteURL = environment.DeleteURL;
    // this.DefaultURL = environment.DefaultURL;
    // this.TemplateURL = environment.TemplateURL;
    // this.Directory = environment.Directory;

    // if (this.Debug === true) {
    //   this.SourceURL = 'http://localhost:8100/';
    //   this.URL = 'http://bispen/unmds/r2t2post.php';
    //   this.NotificationURL = 'http://bispen/unmds/onesignal.php';
    //   this.UploadURL = 'http://bispen/unmds/upload.php';
    //   this.DeleteURL = 'http://bispen/unmds/delete.php';
    //   this.DefaultURL = 'http://bispen/unmds/uploads/';
    //   this.TemplateURL ='http://bispen/unmds/templates/';
    //   this.Directory = 'C:\\inetpub\\wwwroot\\unmds\\uploads\\';
    // } else {
      this.SourceURL = 'https://unmds.testyoursolutions.com/';
      this.URL = 'https://unmds.testyoursolutions.com/unmds/r2t2post.php';
      this.NotificationURL = 'https://unmds.testyoursolutions.com/unmds/onesignal.php';
      this.UploadURL = 'https://unmds.testyoursolutions.com/unmds/upload.php';
      this.DeleteURL = 'https://unmds.testyoursolutions.com/unmds/delete.php';
      this.DefaultURL = 'https://unmds.testyoursolutions.com/unmds/uploads/';
      this.TemplateURL = 'https://unmds.testyoursolutions.com/unmds/templates/';
      this.Directory = 'https://unmds.testyoursolutions.com/unmds/uploads/';
    // }

    this.UserDefaults();
  }

  UserDefaults() {
    this.User['UsersKey'] = '';
    this.User['FirstName'] = '';
    this.User['UserName'] = '';
    this.User['Administrator'] = false;
    this.User['Internal'] = false;
    this.User['UserType'] = '0';
    this.User['InHouseSales'] = false;
    this.User['AccountManager'] = false;
    this.User['TechSales'] = false;
    this.User['SolutionsEngineer'] = false;
    this.User['SalesUnit'] = '';
    this.User['Vendor'] = '';
    this.User['Currency'] = '';
    this.User['ModulesString'] = '';
    this.User['RightsString'] = '';
    this.User['Modules'] = new Array();
    this.User['Rights'] = {};
    this.User['PictureURL'] = '';
    this.User['Signature'] = '';
    this.User['TokenID'] = '';
    this.User['IPAddress'] = '';
  }

  SetUserConfig(Config: string, Value: any = null) {
    if ((['Modules', 'Rights']).indexOf(Config) !== -1) {
      if (this.User[Config + 'String'] !== '') {
        this.User[Config] = JSON.parse(this.User[Config + 'String']);
      }
    } else {
      switch (typeof(this.User[Config])) {
        case 'boolean':
          this.User[Config] = this.ToBoolean(Value);
          break;

        case 'number':
          this.User[Config] = this.ParseFloat(Value);
          break;

        default:
          this.User[Config] = Value;
          break;
      }
    }
  }

  IsUndefined(Parameter: any, Arbitrary: any) {
    if ((Parameter === undefined) &&
        (Arbitrary === undefined)) {
      return '';
    } else if ((Parameter === null) ||
               (Arbitrary === null)) {
      return '';
    } else if (Parameter === undefined) {
      return Arbitrary;
    } else if (typeof(Parameter) === 'string') {
      return (Parameter.trim());
    } else {
      return Parameter;
    }
  }

  ToBoolean(Input: any) {
    if ((Input === undefined) ||
        (Input === null)) {
      return (false);
    } else {
      switch (Input.toString().toUpperCase()) {
        case '1':
        case 'TRUE':
          return true;
          break;
        default:
          return false;
          break;
      }
    }
  }

  ParseFloat(number: string, AllowNegative: boolean = true) {
    if (this.IsUndefined(number, '') !== '') {
      let returnStr: string;
      returnStr = this.IsUndefined(number, '0').toString();
      returnStr = returnStr.replace (/,/g, '');
      if ((parseFloat(returnStr) < 0) &&
          (AllowNegative === false)) {
        return (0);
      } else {
        return (parseFloat(returnStr));
      }
    } else {
      return (0);
    }
  }
}
