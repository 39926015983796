import { Injectable } from '@angular/core';
import { Global4Service } from '../services/global4.service';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})

export class FormService {
  constructor (
    private global: Global4Service
  ) {
  }

  CopyDefaults(Source: object) {
    let Returns = new Array();
    for (let Property in Source) {
      Returns[Property] = Source[Property];
    }
    return (Returns);
  }

  CopyData(Object: object, Source: any) {
    for (let Property in Object) {
      Object[Property] = Source[Property];
    }
  }

  GetData(Object: object, Data: object, Exclusions: any = [], Times: any = []) {
    for (let Property in Object) {
      if (Exclusions.indexOf(Property) === -1) {
        if (Times.indexOf(Property) !== -1) {
          Object[Property] = this.global.CheckDate('1900-01-01 ' + Data[Property.replace(/_/g, '')].toString());
        } else {
            if (Data[Property.replace(/_/g, '')] !== undefined) {
              switch (typeof(Object[Property])) {
                case 'string':
                  //Object[Property] = this.global.decodeHtmlEntity(this.global.IsUndefined(Data[this.global.Replace(Property, '_', '')], '').toString());
                  Object[Property] = this.global.IsUndefined(Data[this.global.Replace(Property, '_', '')], '').toString();
                  break;

                case 'number':
                  Object[Property] = this.global.IsUndefined(Data[this.global.Replace(Property, '_', '')], 0).toString();
                  break;

                case 'boolean':
                  Object[Property] = this.global.ToBoolean(Data[this.global.Replace(Property, '_', '')]);
                  break;

                case 'object':
                  Object[Property] = this.global.CheckDate(Data[Property.replace(/_/g, '')].toString());
                  break;
              }
            } else {
              Object[Property] = null;
            }
        }
      }
    }
  }

  SetParameter(Tag: string, ID: string, Object: object, AutoCompletes: any = [], DateTimes: any = [], TempKey: string = '', WithTempKey: boolean = false, ParentModule: string = '', ParentKey: string = '', TempParentKey: string = '') {
    let AutoCompletesList: any = new Array();
    for (let Object in AutoCompletes) {
      AutoCompletesList.push (Object);
    }

    let Parameter: string =
      '<' + Tag + ' ' +
        Tag + 'ID="' + ID + '" ';

    if (ParentModule.trim() !== '') {
      Parameter += ParentModule + 'Key="' + this.global.XMLEncode(ParentKey) + '" ' +
                   'Temp' + ParentModule + 'Key="' + this.global.XMLEncode(TempParentKey) + '" ';
    }

    if (WithTempKey === true) {
      Parameter += 'Temp' + Tag + 'Key="' + this.global.XMLEncode(TempKey) + '" ';
    }

    for (let Property in Object) {
      if (this.global.InArray(Property, AutoCompletesList) === true) {
        Parameter += Property + '="' + this.global.XMLEncode(AutoCompletes[Property]) + '" ';

      } else if (this.global.InArray(Property, DateTimes) === true) {
        Parameter += Property + '="' + this.global.DateTimeToString(this.global.toISOString(Object[Property])) + '" ';

      } else if (Property !== Tag + 'ID') {
        switch (typeof(Object[Property])) {
          case 'string':
            Parameter += Property + '="' + this.global.XMLEncode(Object[Property]) + '" ';
            break;

          case 'number':
          case 'boolean':
            Parameter += Property + '="' + Object[Property].toString() + '" ';
            break;

          case 'object':
            Parameter += Property + '="' + this.global.DateToString(this.global.toISOString(Object[Property])) + '" ';
            break;
        }
      }
    }
    Parameter += 'UsersKey="' + this.global.XMLEncode(this.global.GetUser('UsersKey')) + '" ';
    Parameter += '/>';
    return (Parameter);
  }

  async Initialize (ParentModule: string, ModuleName: string, Label: string, StoredProcedure: string, Parameter: string, Publish: string = undefined) {
    this.global.Get('Initialize', '', StoredProcedure, Parameter, 'Initializing...', 'A fatal error occurred while trying to execute initialize for ' + Label + '!', 'An unknown error occurred while trying to execute initialize for ' + Label + '!', 'An unknown fatal error occurred while trying to execute initialize for ' + Label + '!', this.global.IsUndefined(Publish, ParentModule.toLowerCase() + ModuleName.toLowerCase() + 'forminitialize'));
  }

  Prompt(ParentModule: string, ModuleName: string, Function: string, Tag: string, Label: string, ID: string) {
    this.global.Alert (
      'Prompt',
      'Are you sure you want to ' + Function.toLowerCase() + ' the record?',
      [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.DeleteRestore(ParentModule, ModuleName, Function, Tag, Label, ID);
          }
        }
      ]
    );
  }

  DeleteRestore(ParentModule: string, ModuleName: string, Function: string, Tag: string, Label: string, ID: string) {
    let LoadMessage: string;
    if (Function === 'Delete') {
      LoadMessage = 'Deleting...';
    } else {
      LoadMessage = 'Restoring...';
    }
    let Parameter: string;
    Parameter = '<' + ModuleName + ' ' +
                  ModuleName + 'ID="' + ID + '" ' +
                  'UsersKey="' + this.global.XMLEncode(this.global.GetUser('UsersKey')) + '" ' +
                '/>';
    this.global.Transaction(ModuleName, ModuleName + '_' + Function, Parameter, ParentModule.toLowerCase() + ModuleName.toLowerCase() + 'formdeleterestore', LoadMessage, 'A fatal error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!', Label + ' was ' + Function.toLowerCase() + 'd successfully!', 'An unknown error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!');
  }

  async Save(ParentModule: string, ModuleName: string, Function: string, Tag: string, Label: string, StoredProcedure: string, Parameter: string, CustomMessages: any = [], Publish: string = undefined, ShowResult: boolean = true) {
    let LoadMessage: string;
    let FatalErrorMessage: string;
    let SuccessMessage: string;
    let UnknownErrorMessage: string;
    if (this.global.CountArray(CustomMessages) > 0) {
      LoadMessage = CustomMessages['LoadMessage'];
      FatalErrorMessage = CustomMessages['FatalErrorMessage'];
      SuccessMessage = CustomMessages['SuccessMessage'];
      UnknownErrorMessage = CustomMessages['UnknownErrorMessage'];
    } else {
      switch (Function) {
        case 'Add':
          LoadMessage = 'Adding...';
          SuccessMessage = Label + ' was added successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!';
          break;
        case 'Save':
          LoadMessage = 'Saving...';
          SuccessMessage = Label + ' was saved successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to execute ' + Function.toLowerCase() + ' for ' + Label + '!';
          break;
        case 'Approve':
          LoadMessage = 'Approving...';
          SuccessMessage = Label + ' was approved successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to execute approving for ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to execute approving for ' + Label + '!';
          break;
        case 'Disapprove':
          LoadMessage = 'Disapproving...';
          SuccessMessage = Label + ' was disapproved successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to execute disapproving for ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to execute disapproving for ' + Label + '!';
          break;
        case 'Send Back':
          LoadMessage = 'Sending Back...';
          SuccessMessage = Label + ' was sent back successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to execute send back for ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to execute send back for ' + Label + '!';
          break;
        case 'Cancel':
          LoadMessage = 'Cancelling...';
          SuccessMessage = Label + ' request was sent successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to send request for ' + Function.toLowerCase() + ' of ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to send request for ' + Function.toLowerCase() + ' of ' + Label + '!';
          break;
        case 'Copy':
          LoadMessage = 'Copying...';
          SuccessMessage = Label + ' was copied successfully!';
          FatalErrorMessage = 'A fatal error occurred while trying to ' + Function.toLowerCase() + ' of ' + Label + '!'
          UnknownErrorMessage = 'An unknown error occurred while trying to ' + Function.toLowerCase() + ' of ' + Label + '!';
          break;
      }
    }
    if (StoredProcedure.trim() === '') {
      StoredProcedure = ModuleName + '_' + Function;
    }
    this.global.Transaction(ModuleName, StoredProcedure, Parameter, this.global.IsUndefined(Publish, ParentModule.toLowerCase() + ModuleName.toLowerCase() + 'formresult'), LoadMessage, FatalErrorMessage, SuccessMessage, UnknownErrorMessage, {}, ShowResult, Tag);
  }

  ShowSublistForm(ParentModule: string, ModuleName: string) {
    $('#' + ParentModule + 'Base').hide();
    $('#' + ParentModule + ModuleName + 'Container').show();
  }

  CloseSublistForm(ParentModule: string, ModuleName: string) {
    $('#' + ParentModule + 'Base').show();
    $('#' + ParentModule + ModuleName + 'Container').hide();
  }

  ClearErrors(ModuleName: string) {
    $('#' + ModuleName + 'Container .errorcontainer').each (function() {
      if ($('#' + $(this).attr('id')).hasClass('hidden') === false) {
        $('#' + $(this).attr('id')).addClass('hidden');
      }
    });
  }

  ShowError(ObjectID: string) {
    if ($('#' + ObjectID).hasClass('hidden') === true) {
      $('#' + ObjectID).removeClass('hidden');
    }
  }

  CheckRequireds(ModuleName: string, entity: any, Valid: boolean = true) {
    for (let node in entity) {
      if ($('#' + ModuleName + node).attr('required') === 'required') {
        switch (typeof(entity[node])) {
          case 'object':
            if (this.global.IsUndefined(entity[node], '') === '') {
              Valid = false;
              this.ShowError(ModuleName + node + 'Required');
            }
            break;

          case 'string':
            if ($('#' + ModuleName + node).attr('type') === 'password') {
            } else if ($('#' + ModuleName + node).hasClass('numeric') === true) {
              if (this.global.ParseFloat(entity[node]) <= 0) {
                Valid = false;
                this.ShowError(ModuleName + node + 'Required');
              }
            } else if (entity[node].trim() === '') {
              Valid = false;
              this.ShowError(ModuleName + node + 'Required');
            }
            break;
        }
      }
    }
    return (Valid);
  }
}
