<!-- <div id="{{ ParentModule + ModuleName }}UploadModal" class="modal"> -->
<div id="UploadModal" class="modal">
  <div class="modalcontent">
    <ion-toolbar class="main">
      <ion-grid>
        <ion-row>
          <ion-col class="ion-hide-md-down" size="11">
            <div class="titlecontainer">
              <div class="title">
                <span class="title">{{ 'Uploads: ' + Title }}</span>
              </div>
            </div>
          </ion-col>
          <ion-col size="1">
            <span class="modalclose" (click)="Close()">&times;</span>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>

    <ion-grid>
      <ion-row>
        <ion-col size="2"></ion-col>
        <ion-col size="4">
          <form id="upload" class="upload" method="post" action="{{ config.UploadURL }}" enctype="multipart/form-data">
            <input id="ModuleName" name="ModuleName" [(ngModel)]="ModuleName" type="hidden" />
            <input id="ParentKey" name="ParentKey" [(ngModel)]="ParentKey" type="hidden" />
            <input id="SN" name="SN" [(ngModel)]="SN" type="hidden" />
            <input id="Multiple" name="Multiple" [(ngModel)]="Multiple" type="hidden" />

            <input id="URL" name="URL" [(ngModel)]="config.URL" type="hidden" />
            <input id="DefaultURL" name="DefaultURL" [(ngModel)]="DefaultURL" type="hidden" />
            <!-- <input id="Directory" name="Directory" [(ngModel)]="config.Directory" type="hidden" /> -->
            <input id="UsersKey" name="UsersKey" [(ngModel)]="UsersKey" type="hidden" />
            <input id="Append" name="Append" [(ngModel)]="Append" type="hidden" />

            <input id="UploadFileName" name="UploadFileName" type="hidden" />

            <button id="Refresh" name="Refresh" color="primary" class="hidden"></button>
            <div id="drop" class="drop">
              Drop Here

              <a>Browse</a>
              <input type="file" name="upl" multiple />
            </div>

            <ul>
            </ul>
          </form>
        </ion-col>
        <ion-col size="5">
          <h4 class="uploadtitle">Uploaded</h4>
          <div id="uploaded" class="content">
            <ion-grid *ngFor="let datum of Data[ModuleName]">
              <ion-row class="datagrid row" [class.even]="((datum.RowNo % 2) === 0)" [class.odd]="((datum.RowNo % 2) === 1)">
                <ion-col>
                  <a href="{{ datum['URL'] }}" target="_blank" download>
                    {{ datum['AttachmentName'] }}
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid>
      <ion-row>
        <ion-col style="text-align: right">
          <ion-button (click)="Close();" color="secondary" fill="solid" size="2x"><ion-icon class="fab" name="arrow-round-back"></ion-icon><div [class.hidden]="global.platformWidth<global.ColMD">&nbsp;{{ 'Close' }}</div></ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>