import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Events } from '../../services/events.service';
import { Config } from '../../config/config.service';
import { Global4Service } from '../../services/global4.service';
import { faFileArchive, faFileAudio, faFileCode, faFileExcel, faFileImage, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFile } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'uploadform',
  templateUrl: './uploadform.component.html',
  styleUrls: ['./uploadform.component.scss'],
})

export class UploadFormComponent implements OnInit, OnDestroy {
  faFileArchive = faFileArchive;
  faFileAudio = faFileAudio;
  faFileCode = faFileCode;
  faFileExcel = faFileExcel;
  faFileImage = faFileImage;
  faFilePdf = faFilePdf;
  faFilePowerpoint = faFilePowerpoint;
  faFileVideo = faFileVideo;
  faFileWord = faFileWord;
  faFile = faFile;

  public SN: string;
  public ParentModule: string;
  public ModuleName: string;
  public Title: string;
  public Multiple: string;
  public Append: string;

  public ParentKey: string;
  public FileName: string = '';
  public Data: any;

  public DefaultURL: string;
  public UsersKey: string;

  constructor(
    public global: Global4Service,
    public config: Config,
    private events: Events,
  ) {
    this.Data = new Array();
    this.ParentKey = '';

    this.DefaultURL = this.global.XMLEncode(this.config.DefaultURL);
    this.UsersKey = this.global.GetUser('UsersKey');

    this.global.LoadScript ('assets/js/jquery1.9.1.min.js');
    this.global.LoadScript ('assets/js/jquery.knob.js');
    this.global.LoadScript ('assets/js/jquery.ui.widget.js');
    this.global.LoadScript ('assets/js/jquery.iframe-transport.js');
    this.global.LoadScript ('assets/js/jquery.fileupload.js');
    this.global.LoadScript ('assets/js/upload.js');
  }

  ngOnDestroy() {
    this.global.Unsubscribes('upload');
  }

  ngOnInit() {
    if (this.global.CheckSubscription('upload', 'uploadopen') === false) {
      this.events.subscribe('uploadopen', (result: any) => {
        this.SN = this.global.GetSchemaName();
        this.ParentModule = result.ParentModule;
        this.ModuleName = result.ModuleName;
        this.Title = result.Title;
        this.Multiple = result.Multiple;
        this.Append = result.Append;
        this.ParentKey = result.ParentKey;
        $('#upload ul').html('');
        $('#uploaded').html('');
        $('#UploadModal').css('display', 'block');
      });
    }
  }

   Close() {
    this.events.publish (this.ParentModule.toLowerCase() + this.ModuleName.toLowerCase() + 'withupload', {
        FileName: this.global.IsUndefined($('#UploadFileName').val(), '').toString().trim()
      });
    $('#UploadModal').css('display', 'none');
  }
}
