import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoadingService } from './services/loading.service';

import { LoginPageModule } from './pages/login/login.module';
import { LoginPage } from './pages/login/login.page';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPage, data: { preload: true } },
  { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule' },
  { path: 'comingsoon', loadChildren: './pages/comingsoon/comingsoon.module#ComingsoonPageModule' },
  { path: 'changepassword', loadChildren: './pages/changepassword/changepassword.module#ChangePasswordPageModule' },

  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', data: { name: '/dashboard' } },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'appsettings', loadChildren: './pages/appsettings/form.module#FormPageModule' },

  { path: 'list', loadChildren: './pages/list/list.module#ListPageModule', data: { name: '/list' } },
  { path: 'listeditable', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule', data: { name: '/listeditable' } },

/* PROFILES */
  { path: 'clientslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'clientsform', loadChildren: './pages/clientsform/form.module#FormPageModule', data: { name: '/clientsform' } },

  { path: 'classificationslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },

  { path: 'productslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'productsform', loadChildren: './pages/productsform/form.module#FormPageModule', data: { name: '/productsform' } },

  { path: 'userslist', loadChildren: './pages/list/list.module#ListPageModule' },
  { path: 'usersform', loadChildren: './pages/usersform/usersform.module#UsersFormPageModule', data: { name: '/usersform' } },

  // { path: 'pricelist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
  { path: 'pricelist', loadChildren: './pages/pricelistslist/form.module#FormPageModule' },

/* PROJECTS */
  { path: 'projectslist', loadChildren: './pages/list/list.module#ListPageModule', data: { name: '/projectslist' } },
  { path: 'teamprojectslist', loadChildren: './pages/list/list.module#ListPageModule', data: { name: '/teamprojectslist' } },
  { path: 'projectsforsendingslist', loadChildren: './pages/list/list.module#ListPageModule', data: { name: '/projectsforsendingslist' } },
  { path: 'registeredprojectslist', loadChildren: './pages/list/list.module#ListPageModule', data: { name: '/registeredprojectslist' } },
  
  { path: 'projectsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: { name: '/projectsform' } },
  { path: 'teamprojectsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: { name: '/teamprojectsform' } },
  { path: 'projectsforsendingsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: { name: '/projectsforsendingsform' } },
  { path: 'registeredprojectsform', loadChildren: './pages/projectsform/form.module#FormPageModule', data: { name: '/registeredprojectsform' } },

  { path: 'projectrequirementsform', loadChildren: './pages/projectrequirementsform/form.module#FormPageModule', data: { name: '/projectrequirementsform' } },
  { path: 'projectrequirementoptionsform', loadChildren: './pages/projectrequirementoptionsform/form.module#FormPageModule', data: { name: '/projectrequirementoptionsform' } },

  { path: 'projectdetailsform', loadChildren: './pages/projectdetailsform/form.module#FormPageModule', data: { name: '/projectdetailsform' } },

/* SOLUTIONS */
{ path: 'forsolutionslist', loadChildren: './pages/solutionslist/form.module#FormPageModule' },
{ path: 'solutionsform', loadChildren: './pages/solutionsform/form.module#FormPageModule', data: { name: '/solutionsform' } },
{ path: 'solutionsmanagementslist', loadChildren: './pages/solutionslist/form.module#FormPageModule' },
{ path: 'solutionledsform', loadChildren: './pages/solutionledsform/form.module#FormPageModule', data: { name: '/solutionledsform' } },
/* PROPOSALS */

  // { path: 'quotationslist', loadChildren: './pages/quotationslist/form.module#FormPageModule' },
  // { path: 'quotationmanagementslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'quotationsform', loadChildren: './pages/quotationsform/form.module#FormPageModule', data: { name: '/quotationsform' } },
  // { path: 'quotationdetailsform', loadChildren: './pages/quotationdetailsform/form.module#FormPageModule', data: { name: '/quotationdetailsform' } },
  // { path: 'quotationuploadsform', loadChildren: './pages/quotationuploadsform/form.module#FormPageModule', data: { name: '/quotationuploadsform' } },

  // { path: 'quotationledsform', loadChildren: './pages/quotationledsform/form.module#FormPageModule', data: { name: '/quotationledsform' } },

  // { path: 'quotationapprovalslist', loadChildren: './pages/list/list.module#ListPageModule' },
  // { path: 'quotationapprovalsform', loadChildren: './pages/quotationsform/form.module#FormPageModule', data: { name: '/quotationapprovalsform' } },

  // { path: 'quotationuploadapprovalslist', loadChildren: './pages/listeditable/listeditable.module#ListEditablePageModule' },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: LoadingService }),
    LoginPageModule
  ],
  exports: [RouterModule],
  providers: [LoadingService]
})
export class AppRoutingModule {}